<template>
  <section class="app-ecommerce-details">
    <b-card
        v-if="product"
        no-body
    >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                  :src="product.imagen"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
              cols="12"
              md="7"
          >
            <!-- Product Name -->
            <h4 class="font-weight-bolder" >{{ product.name }}</h4>
            <b-card-text class="item-company mb-0">
              <p class="company-name">
                <strong>{{ $t('Modelo') }}:</strong> {{ product.modelo }}
              </p>
            </b-card-text>
            <b-card-text class="item-company mb-0">
              <p class="company-name">
                <strong>{{ $t('codigoArticulo') }}:</strong> {{ product.codart }}
              </p>
            </b-card-text>
            <b-card-text class="item-company mb-0">
              <p class="company-name">
                <strong>{{ $t('ean13') }}:</strong> {{ product.ean13 }}
              </p>
            </b-card-text>
            <b-card-text
              class="item-company mb-0"
              v-if="product.manualUso || product.certificadoConformidad || product.fichaTecnica"
            >
              <p class="company-name">
                <a
                  :href="product.manualUso"
                  target="_blank"
                  v-if="product.manualUso"
                  class="mr-1 link"
                >
                  {{ $t('manualUso') }}
                </a>
                <a
                  :href="product.certificadoConformidad"
                  target="_blank"
                  v-if="product.certificadoConformidad"
                  class="mr-1 link"
                >
                  {{ $t('certificadoConformidad') }}
                </a>
                <a
                  :href="product.fichaTecnica"
                  target="_blank"
                  v-if="product.fichaTecnica"
                  class="mr-1 link"
                >
                  {{ $t('fichaTecnica') }}
                </a>
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BFormSelect, BTable, BPagination, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BLink,
    BFormSelect,
    BPagination,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusAssets: config.statusAssetsVariants,
    }
  },
  watch: {
    currentPage() {
      this.chargeData()
    },
    pageLength() {
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      product: 'products/getProduct',
    }),
  },
  methods: {
    ...mapActions({
      getProduct: 'products/getProduct',
    }),
  },
  async created() {
    await this.getProduct(this.$route.params.id)
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
